.navbar {
  display: flex;
  margin: 50px auto;

  a.logo {
    text-decoration: none;
    margin: auto 0;
    font-family: "Roboto Mono", monospace;
    font-size: 1.7em;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  .nav-container {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    ul {
      list-style-type: none;
      padding: 0;
  
      li {
        display: inline-block;
        margin: 0 10px;
        padding: 8px 13px;
        border-radius: 25px;
        cursor: pointer;
        -webkit-transition: all .25s ease-in-out;
        -ms-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;
      }

      li:last-child {
        margin-right: 0;
      }
  
      a:hover:not(.selected) li {
        background-color: #d6d3c2;
        opacity: 0.6;
      }
  
      a.selected li {
        background-color: #ffd781;
  
        &:hover {
          background-color: #ceab60;
        }
      }
    }
  
    .connect-wallet-btn {
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 10px;
      background-color: var(--primary-color);
      color: #fff;
      margin: auto 0 auto 10px;
      display: flex;
      font-size: 1em;
  
      .icon {
        margin: auto 8px auto auto;
      }
    }
  
    .wallet-address {
      margin: auto 0 auto 10px;
      background-color: #fff;
      border-radius: 10px 15px;
      padding: 10px 15px;
      border: var(--primary-color) 1px solid;
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .incorrect-chain {
      display: block;
      width: 150px;
      text-align: center;
      margin: auto 0 auto 10px;
      background-color: #fff;
      border-radius: 10px 15px;
      padding: 10px 15px;
      border: red 2px solid;
      color: red;
      font-weight: 700;
      cursor: pointer;
  
      .icon {
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .navbar {
    margin: 20px auto;
    a.logo {
      margin: 15px 0;
    }
  }
}
