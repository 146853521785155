.article {
    margin-bottom: 50px;
    .carousel {
        cursor: pointer;
    }

    .videos-container {
        margin: 20px auto;
        width: 90%;
    }
}