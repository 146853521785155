.add-news {
    h1, .header-description {
        text-align: center;
    }
    
    form {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
    
        .fields {
            display: block;
            .form-group {
                margin: 15px 0;
                label {
                    display: block;
                }
                input[type=text], textarea {
                    width: 100%;
                    padding: 10px;
                    border-radius: 10px;
                    border: 1px var(--primary-color) solid;
                    font-size: 1.1em;
                    font-family: "Nunito", sans-serif;
                }
                input[type=file] {
                    width: 100%;
                    padding: 10px;
                    border-radius: 10px;
                    border: 1px var(--primary-color) solid;
                    font-family: "Nunito", sans-serif;
                }
            }
        }
        .ar-info {
            margin: 15px 0;
            a.get-free-ar {
                font-size: 0.7em;
                margin-left: 5px;
            }
            .balance {
                display: flex;
                .ar-info-skeleton {
                    width: 100px;
                    margin-left: 5px;
                }
                .value {
                    margin-left: 5px;
                }
            }
        }
        .upload-status {
            margin: 15px 0;
            display: flex;
            .loading-spin {
                margin: auto 5px auto auto;
            }
            .progress-message {
                margin: auto auto auto 5px;
            }
            .upload-error {
                color: red;
                margin: auto;
            }
        }
        .upload-btn {
            background-color: var(--primary-color);
            color: #fff;
            padding: 10px 25px;
            border-radius: 20px;
            cursor: pointer;
            margin: auto;
            border: none;
            outline: none;
        }
    }
}